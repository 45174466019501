import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
import "@fontsource/caudex";


function App() {
  const [totalMeals, setTotalMeals] = useState(1700);

  useEffect(() => {
    const incrementMeal = () => {
      setTotalMeals((prevTotalMeals) => prevTotalMeals + 1);
    };

    const currentTime = new Date();
    const endOfDay = new Date(currentTime);
    endOfDay.setHours(23, 59, 59, 999); // Set the end of the day to 23:59:59.999

    const remainingTime = endOfDay - currentTime;
    const mealsToAdd = 60 - (totalMeals % 60);
    const interval = setInterval(() => {
      if (totalMeals < 1760) {
        // 1700 + 60
        incrementMeal();
      } else {
        clearInterval(interval);
      }
    }, remainingTime / mealsToAdd);

    return () => clearInterval(interval);
  }, [totalMeals]);

  return (
    <div className="App">
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-black fixed-top">
          <div className="container">
            <a className="navbar-brand" href="#home">
              <img
                src="/client-logos/logo.png"
                alt="Logo"
                style={{ width: '50px', height: 'auto' }}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarNav"
            >
              <ul className="navbar-nav" style={{ fontSize: '1.2rem' }}>
                <li className="nav-item">
                  <a className="nav-link" href="#hero">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#services">
                    Our Services
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#delivery-zones">
                    Delivery Zones
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#clients">
                    Clients
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#total-meals">
                    Total Meals Delivered
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <section
          className="hero d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: '80vh' }}
          id="hero"
        >
          <div className="container text-center">
            <h1>Aak Deliveries Inc.</h1>
            <h3>You make, We Deliver!</h3>
          </div>
        </section>
        <section
          className="about text-center"
          style={{ minHeight: '80vh' }}
          id="about"
        >
          <div className="container">
            <h2>About Us</h2>
            <p>
              At Aak Deliveries Inc., we're more than just a food delivery
              service. We're a logistics handling, packaging management, and
              route optimization company operating in the vibrant Metro
              Vancouver area. Our primary goal is to seamlessly connect
              discerning customers with delectable meals from their favorite
              local eateries, delivered straight to their doorsteps.
            </p>
            <p>
              With a dedicated team of logistics experts, we ensure the smooth
              handling and packaging of every order. Rain, Snow or shine,
              whether you're nestled indoors or soaking up the sun outdoors,
              we're here to cater to your needs.
            </p>
            <p>
              Moreover, our commitment extends beyond mere delivery. We
              prioritize community support by forging partnerships with local
              businesses and advocating for sustainability. By choosing Aak
              Deliveries, you're not just satisfying your hunger; you're also
              fostering a greener, more resilient local food ecosystem.
            </p>
            <p>
              Join us in our mission to revolutionize food delivery in Metro
              Vancouver. Get in touch today and discover the unparalleled
              convenience of Aak Deliveries!
            </p>
          </div>
        </section>

        <section
          className="services text-center"
          style={{ minHeight: '80vh' }}
          id="services"
        >
          <div className="container">
            <h2>Our Services</h2>
            <ul>
              <li>Route Optimization</li>
              <li>Customer Handling</li>
              <li>Packaging</li>
              <li>Sorting</li>
            </ul>
          </div>
        </section>
        <section
          className="delivery-zones text-center"
          style={{ minHeight: '80vh' }}
          id="delivery-zones"
        >
          <div className="container">
            <h2>Delivery Zones</h2>
            <p>We currently deliver to the following areas:</p>
            <ul>
              <li>North Vancouver</li>
              <li>Vancouver</li>
              <li>UBC</li>
              <li>Burnaby</li>
              <li>SFU</li>
              <li>Coquitlam</li>
              <li>Port Moody</li>
              <li>Richmond</li>
              <li>Delta</li>
              <li>Surrey</li>
              <li>Langley</li>
              <li>Abbotsford</li>
            </ul>
            <p>
              Don't see your area listed? Contact us to inquire about delivery
              availability in your area.
            </p>
          </div>
        </section>
        <section
          className="clients text-center"
          style={{ minHeight: '80vh' }}
          id="clients"
        >
          <div className="container">
            <h2>Our Clients</h2>
            <div className="client-logos">
              <a
                href="https://www.instagram.com/thefanasia_kitchen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/client-logos/fanasia.png"
                  alt="The Fanasia's Kitchen"
                  style={{ width: '12rem', height: '12rem' }}
                />
              </a>{' '}
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/client-logos/desiswaad.png"
                  alt="Client Logo 2"
                  style={{ width: '7rem', height: '7rem' }}
                />
              </a>{' '}
              {/* Add more client logos as needed */}
            </div>
          </div>	
        </section>
        <section
          className="total-meals text-center"
          style={{ minHeight: '80vh' }}
          id="total-meals"
        >
          <div className="container">
            <h2>Total Meals Delivered</h2>
            <p style={{ fontSize: '4rem' }}>{totalMeals}</p>
          </div>
        </section>
        <section
          className="contact text-center"
          style={{ minHeight: '80vh' }}
          id="contact"
        >
          <div className="container">
            <h2>Contact Us</h2>
            <p>
              For any inquiries or feedback, please feel free to contact us:
            </p>
            {/* <p>Email: info@aakdeliveries.com</p> */}
            <p>Phone: +1 (236)-867-1542 || +1 (236)-660-4147</p>
            {/* Add more contact information as needed */}
          </div>
        </section>
        {/* Add more sections for different content */}
      </main>
      <footer className="footer">
        <section>
          <div className="container text-center">
            <p>
              &copy; {new Date().getFullYear()} Aak Deliveries Inc. All rights
              reserved.
            </p>
          </div>
        </section>
      </footer>
    </div>
  );
}

export default App;
